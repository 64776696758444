import React, { createContext, useReducer } from "react";

const initialState = {
  cursorText: false,
  siteLoaded:
    typeof window !== "undefined"
      ? window?.innerWidth > 1024
        ? false
        : true
      : true,
  isIdle: false,
};

export const StateContext = createContext();

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
};

const useSiteContext = () => {
  const context = React.useContext(StateContext);
  if (context === undefined) {
    throw new Error(`useSiteContext must be used within a SiteProvider`);
  }
  return context;
};

//create a reducer to update cursorText to the value passed in
const reducer = (state, action) => {
  switch (action.type) {
    case "CURSOR_TEXT":
      return {
        ...state,
        cursorText: action.payload,
      };
    case "SITE_LOADED":
      return {
        ...state,
        siteLoaded: action.payload,
      };
    case "IS_IDLE":
      return {
        ...state,
        isIdle: action.payload,
      };
    default:
      return state;
  }
};

export { StateProvider, useSiteContext };
