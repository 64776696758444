exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-simple-page-js": () => import("./../../../src/templates/simplePage.js" /* webpackChunkName: "component---src-templates-simple-page-js" */)
}

